html {
    scroll-behavior: smooth;
}

.music-box {
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
     font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
     font-weight: 100;
}

@keyframes reveal-up {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 100;
        transform: translateY(0px);
    }
}

.reveal-up {
    animation: reveal-up 800ms cubic-bezier(0.65, 0, 0.35, 1);
}

.bg-gradient {
    background-image: linear-gradient(16deg, rgba(31,32,73,1) 0%, rgba(107,109,255,1) 100%);
}