@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'aos/dist/aos.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-main-500 text-white font-roboto
}

.player-item {
    @apply bg-black/25 text-lg border-solid border-black p-2 mb-1 hover:bg-black/30 cursor-pointer text-center sm:text-left
}

.player-item.selected {
    @apply text-sec-500 bg-black/50 font-semibold hover:bg-black/50
}

.link {
    @apply text-gray-300 hover:text-white transition-all duration-150
}

.icon {
    @apply text-2xl text-gray-500 hover:text-white transition-all duration-150 inline-block
}

.icon-nosize {
    @apply text-gray-500 hover:text-white transition-all duration-150 inline-block
}

.btn {
    @apply transition-all duration-300 cursor-pointer
}

.header {
    @apply text-3xl font-semibold text-center
}

.active {
    @apply text-white
}

.input {
    @apply border-solid border-gray-500 rounded-md border-[1px] focus:outline-none
}

.pop-in {
    animation-name: popin;
    animation-duration: 0.4s;

}

@keyframes popin {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
    
}